* {
  padding: 0;
  margin: 0;
}

.delete__button {
  display: flex;
  width: 21px;
  min-width: 21px;
  height: 21px;
  padding: 0px;
  display: block;
  position: relative;
  bottom: -2px;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 3px;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-right: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 3px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--dark-purple);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  margin-bottom: 3px;
  width: 3.5px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* session box styling*/
.session__box {
  background-color: #ffffff;
  padding: 1rem;
  margin: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.input {
  padding: 0.5rem 1rem;
  margin: 0rem 1rem;
}

.ses__button {
  margin-left: 1rem;
  /* width: 10rem; */
}

.student__link {
  color: var(--dark-purple);
  font-weight: bold;
}

.content__box {
  background-color: var(--white);
  padding: 3rem;
  margin: 2rem;
}

.answer__box {
  width: 25rem;
}

/* poll editor styling*/

#poll__admin {
  width: 90%;
  margin: 0 auto;
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.poll__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

.input__line {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
  grid-template-columns: 10rem 25rem 2rem 2rem;
  align-items: center;
  border-bottom: 1px solid rgb(218, 223, 218);
}

.question {
  margin-bottom: 2rem;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.question__input {
  width: 100%;
  padding: 8px;
}

#tutor__question {
  font-size: 1.5rem;
}

.answer__line {
  min-width: 80px;
}

.control__buttons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  
  .session__box {
    padding: 0.5rem;
    margin: 0.5rem;
  }
 
  .ses__button {
    margin-top: 5px;
    margin-bottom: 0px;
    padding: 8px 4px;
  }
  .content__box {
    background-color: var(--white);
    padding: 0.5rem;
    margin: 0.5rem;
  }
  .answer__line {
    margin: 8px 0px;
  }
}

@media screen and (max-width: 480px) {
  
  .session__active {
    display: flex;
    flex-direction: column;
  }
  .answer__line {
    min-width: 0px;
    padding: 0px;
    margin: 0px;
  }
  .answer__label {
    display: none;
  }
  .question__label {
    padding: 0px;
    margin-left: 0px;
    margin-right: 10px;
  }
  .question__input {
    padding: 8px;
  }
}
