form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

form label {
  margin: 1rem 0rem 0rem 0rem;
}

form button {
  margin: 1.5rem 0rem;
}

form input:focus {
  background-color: #ffffff;
}

.login__input {
  padding: 0.75em;
  width: 300px;
}

.login__label {
  color: var(--dark-purple);
  font-weight: 900;
  letter-spacing: 1.5px;
}