body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f2f2;
}

a, a:visited {
  text-decoration: none;
  color: var(--hover-purple);
  font-weight: 900;
}


a:focus {
  background-color: transparent;
}

h1 {
  font-family: "Mark Pro Bold";
}

h2,
h3 {
  font-family: "Mark Pro Medium";
}

.contentBox {
  background-color: #ffffff;
  padding: 3rem;
  margin: 2rem;
}

.align-left {
  text-align: left;
}

/*------ links -------*/

.blue-link {
  text-decoration: none;
  color: #786be4;
}

.blue-link:hover :focus {
  color: #9595f4;
}

/*------ buttons -------*/

.primaryButton {
  background-color: #786be4;
  padding: 1rem;
  display: inline-block;
  text-decoration: none;
  color: #ffffff;
  font-family: "Mark Pro Medium";
  border: none;
  cursor: pointer;
}

.primaryButton:hover :focus {
  background-color: #9595f4;
}

.secondaryButton {
  background-color: #ffffff;
  padding: 1rem;
  display: inline-block;
  text-decoration: none;
  color: #000000;
  font-family: "Mark Pro Medium";
  border: solid 2px #786be4;
  cursor: pointer;
}

.secondaryButton:hover {
  color: #786be4;
}

/*------ colours -------*/

.blue-1 {
  color: var(--dark-purple);
}

.blue-2 {
  color: #9595f4;
}

.blue-3 {
  color: #dbdbfc;
}

.grey-1 {
  color: #414042;
}

.grey-2 {
  color: #949598;
}

.grey-3 {
  color: #d1d3d4;
}

.grey-4 {
  color: #f1f2f2;
}


/*------ fonts -------*/

@font-face {
  font-family: "Mark Pro";
  src: local("Mark Pro"), url("../fonts/Mark Pro.ttf") format("truetype");
}

@font-face {
  font-family: "Mark Pro Bold";
  src: local("Mark Pro Bold"),
    url("../fonts/Mark Pro Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Mark Pro Medium";
  src: local("Mark Pro Medium"),
    url("../fonts/Mark Pro Medium.ttf") format("truetype");
}
