* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* container styling*/
#container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* question styling*/
#question {
  color: var(--dark-grey);
  font-size: 2rem;
}
/* answers section styling*/
#answers {
  width: 90%;
  margin: 1em auto;
  max-width: 690px;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}


/* specific answers section styling*/
#answersList {
  border: 1px solid var(--light-purple);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* answer style before voting*/
.voteButton {
  width: 350px;
  background-color: var(--dark-purple);
  margin: 0.2em;
  display: inline-block;
  opacity: 1;
  transition: background-color 0.7s ease-in, opacity 0.4s ease-in;
  cursor: pointer;
  /* overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap; */
}

/*users answer before reveal*/
.selectedVoteButton {
  width: 350px;
  /* Overwriting dynamic inline colors */
  background-color: var(--dark-purple) !important;
  opacity: 1;
  margin: 0.2em;
  display: inline-block;
  transition: background-color 1s ease-in;
  cursor: pointer;
}

.selectedVoteButton:hover {
  background-color: var(--dark-purple);
}
/*ignored answers before reveal*/
.unselectedVoteButton {
  width: 350px;
  background-color: var(--mid-grey);
  margin: 0.2em;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.7s ease-in, opacity 0.7s ease-in;
}

.unselectedVoteButton:hover {
  background-color: var(--mid-grey);
}

/*users correct answer after reveal*/
.userCorrect {
  width: 350px;
  background-color: var(--dark-green) !important;
  opacity: 1;
  margin: 0.2em;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.7s ease-in;
}

.userCorrect:hover {
  background-color: var(--dark-green) !important;
}

/*users incorrect answer after reveal*/
.userIncorrect {
  width: 350px;
  background-color: var(--dark-purple) !important;
  margin: 0.2em;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.7s ease-in, opacity 0.7s ease-in;
}

.userIncorrect:hover {
  background-color: #888888;
}

/*unselected answer that is correct on reveal*/
.userWrongButAnswerCorrect {
  width: 350px;
  background-color: var(--dark-green) !important;
  opacity: 1;
  margin: 0.2em;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.7s ease-in, opacity 0.7s ease-in;
}

.userWrongButAnswerCorrect:hover {
  background-color: var(--dark-green) !important;
}

@media screen and (max-width: 480px ){
  #answersList {
    border: 0px
  }

  .voteButton, .selectedVoteButton, .selectedVoteButton:hover,.unselectedVoteButton, .unselectedVoteButton:hover, .userCorrect, .userCorrect:hover, .userIncorrect, .userIncorrect:hover, .userWrongButAnswerCorrect, .userWrongButAnswerCorrect:hover {
    width: 80vw;
    overflow: hidden;
  }

  #question {
    font-size: 1.5rem;
  }
}