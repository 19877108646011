.App {
  text-align: center;
  font-family: "Mark Pro";
}

:root {
  --dark-purple: #786be4;
  --mid-purple: #9595f4;
  --light-purple: #dbdbfc;
  --hover-purple: #665cc6;
  --dark-green : #3dd980;
  --nc-red: #eb1c24;
  --dark-grey: #414042;
  --mid-grey: #949598;
  --light-grey: #d1d3d4;
  --very-light-grey: #f1f2f2;
  --black: #000000;
  --white: #ffffff;
}

/* Name of app */
h1 {
  font-size: 2rem;
}
/* Individual page names */
h2 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-family: "Mark Pro Medium";
}
/* Highlighted sub-heading */
h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-family: "Mark Pro";
  color: var(--dark-purple);
}

input:focus {
  outline-color: #786be4;
}

button,
.primaryButton {
  background-color: var(--dark-purple);
  padding: 0.5em 1em;
  color: #ffffff;
  font-family: "Mark Pro Medium";
  border: none;
  width: 160px;
  height: 65px;
  border-radius: 3px;
  font-size: 1.2rem;
  letter-spacing: 0.35px;
  cursor:pointer;
}


button:hover,
.primaryButton:hover {
  background-color: var(--hover-purple);
}

.primaryButton {
  padding: 1.2rem;
  display: inline-block;
  text-decoration: none;
}

button:disabled, .primaryButton:disabled {
  background-color: var(--light-purple);
}

button[disabled]:hover {
  background-color: var(--light-purple);
}

button.selectedVoteButton:disabled {
  background-color: var(--dark-purple);
}

button.unselectedVoteButton:disabled{ 
  background-color: var(--light-grey);
}


