nav {
  background-color: var(--white);
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 6px solid var(--light-purple);
  height: 7.5em;
}

.nav-link,
.nav-link:visited {
  text-decoration: none;
  color: var(--dark-purple);
  font-family: "Mark Pro Medium";
  margin: 0rem 1rem;
}

.nav-link:hover :focus {
  color: var(--mid-purple);
}

.logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 100px;
  display: none;
}

.home__link__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__link {
  display: block;
}

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  position: absolute; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 117px;
  right: -6px;
  height: auto;
  background-color: var(--white); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding: 1em 0;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  border-bottom: 6px solid var(--light-purple);
  border-top: 6px solid var(--light-purple);
  border-left: 6px solid var(--light-purple);
}

/* The button used to open the sidebar */
.openbtn {
  padding: 0px 0px 5px 0px;
  margin: 0;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  position: absolute;
  top: 1em;
  right: 0.5em;
}

.closebtn {
  position: absolute;
  top: 1em;
  right: 0.5em;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  padding: 0px 0px 5px 0px;
  margin: 0;
  width: 50px;
  height: 50px;
  font-size: 2rem;
}

/* The sidebar links */
.sidebar p {
  /* padding: 8px 8px 8px 32px; */
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s; /* If you want a transition effect */
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  /* .sidebar {padding-top: 15px;} */
  .sidebar p {
    font-size: 18px;
  }
}

.sidebar__open__true {
  width: 200px;
  transition: width 0.5s ease-in;
}

.sidebar__open__false {
  width: 0px;
}

@media screen and (min-width: 680px) {
  .nav {
    height: 10em;
  }
  .home__link__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home__link {
    display: block;
  }
  .logo {
    position: absolute;
    top: 25px;
    left: 2rem;
    width: 150px;
  }
  .openbtn {
    padding: 0px 0px 5px 0px;
    margin: 0;
    width: 50px;
    height: 50px;
    font-size: 2rem;
    position: absolute;
    top: 1.55em;
    right: 2rem;
  }
}

@media screen and (min-width: 480px) {
  .logo {
    display: initial;
  }
}

@media screen and (max-width: 480px ){
  nav {
    padding: 1.5em 2em;
    height: 3em
  }
}