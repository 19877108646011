.pie__chart {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.7s ease-in;
  width: 100%;
}

.hidden__pie__chart {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.total__count {
    color: var(--black);
    margin-top:1.2rem
}

.vote__loading {
  margin: 20px 0;
}

.vote__loading {
  margin: 20px 0;
}

.legend__list {
  list-style:none;
  align-content: center;

}

.legend__item {
  display:grid;
  grid-template-columns: 1fr auto;
  text-align: left;
  align-content:center;
  margin: 4px auto;
  width:250px;
  padding: 2px 4px;
  color: var(--white);
  border-radius: 3px;
  height: 3em;
}

.legend__value {
  /* text-align: right; */
}

.legend__total {
  color: var(--black)
}

.pie__legend__wrapper {
  display: flex;
}

@media screen and (max-width: 600px) {
 
 
}